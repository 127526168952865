<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})
</script>
<template>
  <svg
    role="img"
    aria-label="browse icon"
    class="browse-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>Browse</title>

    <g v-if="!props.active">
      <path
        d="M4.25 4.48999C4.66 4.48999 5 4.82999 5 5.23999V22.75C5 23.16 4.66 23.5 4.25 23.5C3.84 23.5 3.5 23.16 3.5 22.75V5.23999C3.5 4.82999 3.84 4.48999 4.25 4.48999ZM4.25 2.98999C3.01 2.98999 2 3.99999 2 5.23999V22.75C2 23.99 3.01 25 4.25 25C5.49 25 6.5 23.99 6.5 22.75V5.23999C6.5 3.99999 5.49 2.98999 4.25 2.98999Z"
      />
      <path
        d="M11.75 4.48999C12.16 4.48999 12.5 4.82999 12.5 5.23999V22.75C12.5 23.16 12.16 23.5 11.75 23.5C11.34 23.5 11 23.16 11 22.75V5.23999C11 4.82999 11.34 4.48999 11.75 4.48999ZM11.75 2.98999C10.51 2.98999 9.5 3.99999 9.5 5.23999V22.75C9.5 23.99 10.51 25 11.75 25C12.99 25 14 23.99 14 22.75V5.23999C14 3.99999 12.99 2.98999 11.75 2.98999Z"
      />
      <path
        d="M17.64 4.33001C17.95 4.33001 18.22 4.51001 18.34 4.80001L25.52 22.56C25.62 22.81 25.56 23.03 25.52 23.13C25.47 23.24 25.36 23.43 25.11 23.53C25.02 23.57 24.93 23.59 24.83 23.59C24.52 23.59 24.25 23.41 24.13 23.12L16.95 5.36001C16.8 4.98001 16.98 4.54001 17.36 4.38001C17.45 4.34001 17.54 4.32001 17.64 4.32001M17.64 2.82001C17.36 2.82001 17.07 2.87001 16.8 2.98001C15.65 3.45001 15.09 4.76001 15.56 5.91001L22.74 23.67C23.09 24.55 23.94 25.08 24.83 25.08C25.11 25.08 25.4 25.03 25.67 24.92C26.82 24.45 27.38 23.14 26.91 21.99L19.73 4.23001C19.38 3.35001 18.53 2.82001 17.64 2.82001Z"
      />
    </g>
    <g v-else>
      <path
        d="M4.25 2.98999C3.01 2.98999 2 3.99999 2 5.23999V22.75C2 23.99 3.01 25 4.25 25C5.49 25 6.5 23.99 6.5 22.75V5.23999C6.5 3.99999 5.49 2.98999 4.25 2.98999Z"
      />
      <path
        d="M11.75 2.98999C10.51 2.98999 9.5 3.99999 9.5 5.23999V22.75C9.5 23.99 10.51 25 11.75 25C12.99 25 14 23.99 14 22.75V5.23999C14 3.99999 12.99 2.98999 11.75 2.98999Z"
      />
      <path
        d="M26.91 22L19.73 4.24002C19.38 3.36002 18.53 2.83002 17.64 2.83002C17.36 2.83002 17.07 2.88002 16.8 2.99002C15.65 3.46002 15.09 4.77002 15.56 5.92002L22.74 23.68C23.09 24.56 23.94 25.09 24.83 25.09C25.11 25.09 25.4 25.04 25.67 24.93C26.82 24.46 27.38 23.15 26.91 22Z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.browse-icon {
  fill: var(--p-text-color);
  height: 52px;
}
</style>
